// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

;globalThis["_sentryRewritesTunnelPath"] = undefined;globalThis["SENTRY_RELEASE"] = {"id":"5.41.0 \r\n"};globalThis["_sentryBasePath"] = undefined;globalThis["_sentryRewriteFramesAssetPrefixPath"] = "";import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;
const SAMPLE_RATE =
  process.env.NEXT_PUBLIC_ENVIRONMENT === 'development' ? 0.0 : 1.0;
const TRACE_RATE =
  process.env.NEXT_PUBLIC_ENVIRONMENT === 'development' ? 0.0 : 0.01;
const REPLAY_SESSION_RATE =
  process.env.NEXT_PUBLIC_ENVIRONMENT === 'development' ? 0.0 : 0.0;
const REPLAY_ERROR_RATE =
  process.env.NEXT_PUBLIC_ENVIRONMENT === 'development' ? 0.0 : 0.05;

// This is an attempt to block errors from these places if they show up in the stacktrace
const EXCLUDE_FILES = ['widget.js', 'klaviyo', 'yotpo'];

Sentry.init({
  dsn:
    SENTRY_DSN ||
    'https://f279d26de7af4a3a9009aaf27860070c@o4504046256390144.ingest.sentry.io/4504081754685440',
  // Adjust this value in production, or use tracesSampler for greater control
  environment: process.env.NEXT_PUBLIC_ENVIRONMENT,
  tracesSampleRate: TRACE_RATE,
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
  sampleRate: SAMPLE_RATE,
  replaysSessionSampleRate: REPLAY_SESSION_RATE,
  replaysOnErrorSampleRate: REPLAY_ERROR_RATE,
  // Added the below from: https://docs.sentry.io/clients/javascript/tips/
  ignoreErrors: [
    //   // Random plugins/extensions
    //   'top.GLOBALS',
    //   // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
    //   'originalCreateNotification',
    //   'canvas.contentDocument',
    //   'MyApp_RemoveAllHighlights',
    //   'http://tt.epicplay.com',
    //   "Can't find variable: ZiteReader",
    //   'jigsaw is not defined',
    //   'ComboSearch is not defined',
    //   'http://loading.retry.widdit.com/',
    //   'atomicFindClose',
    //   // Facebook borked
    //   'fb_xd_fragment',
    //   // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to reduce this. (thanks @acdha)
    //   // See http://stackoverflow.com/questions/4113268/how-to-stop-javascript-injection-from-vodafone-proxy
    //   'bmi_SafeAddOnload',
    //   'EBCallBackMessageReceived',
    //   // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
    //   'conduitPage',
    //   // Generic error code from errors outside the security sandbox
    //   // You can delete this if using raven.js > 1.0, which ignores these automatically.
    //   'Script error.',
    //   // Avast extension error
    //   '_avast_submit',
    //   // This is all the local storage issues (which are handled)
    //   'The operation is insecure',
    //   'No available storage method found',
    //   // Yotpo
    // "Cannot read property 'layout_name' of null",
    //   // Other
    //   'ResizeObserver loop limit exceeded',
    //   'TimeoutError: Transaction timed out due to inactivity',
    //   'Invariant: attempted to hard navigate to the same URL',
    //   // https://github.com/getsentry/sentry-javascript/issues/3440
    //   'Non-Error promise rejection captured',
    // ],
    // denyUrls: [
    //   // Google Adsense
    //   /pagead\/js/i,
    //   // Facebook flakiness
    //   /graph\.facebook\.com/i,
    //   // Facebook blocked
    //   /connect\.facebook\.net\/en_US\/all\.js/i,
    //   // Woopra flakiness
    //   /eatdifferent\.com\.woopra-ns\.com/i,
    //   /static\.woopra\.com\/js\/woopra\.js/i,
    //   // Chrome extensions
    //   /extensions\//i,
    //   /^chrome:\/\//i,
    //   // Other plugins
    //   /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
    //   /webappstoolbarba\.texthelp\.com\//i,
    //   /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
    // Added by Bulk
    '/klaviyo.com/',
    '/yotpo.com/',
    '/zdassets.com/',
    '/hotjar.com/',
    '/loyaltylion.net/',
    '/algolia.io/',
    '/algolia.net/',
    '/kaltura.com/',
    //   '/safari-extension/',
    //   '/safari-web-extension/',
  ],
  beforeSend(event) {
    let exclude = false;
    try {
      event.exception.values.forEach((error) => {
        // Check each exception
        if (
          error.stacktrace.frames.findIndex((st) =>
            EXCLUDE_FILES.some((str) => st.filename.includes(str))
          )
        )
          exclude = true;
      });
    } catch (err) {
      // stacktrace unavailable
      return event;
    }

    if (exclude) {
      return null;
    }
    return event;
  },
  integrations: [
    Sentry.replayIntegration({
      // Additional SDK configuration goes in here, for example:
      maskAllText: false,
      maskAllInputs: true,
      blockAllMedia: false,
    }),
  ],
});
